<template>
  <v-container style="min-width: 450px" fluid class="pa-2">
    <v-subheader>Favoritos</v-subheader>
    <v-divider></v-divider>

    <v-list nav>
      <v-list-item-group>
        <v-list-item v-for="fav in favoritos" :key="fav.idEstudio" :to="`/detalles_estudio?idEstudio=${fav.idEstudio}`">

          <v-list-item-icon>
            <v-btn icon @click.stop="toggleFav(fav.idEstudio)">
              <v-icon>mdi-star</v-icon>
            </v-btn>
          </v-list-item-icon>

          <v-list-item-title>
            {{fav.idEstudio}} - {{fav.nombreProyecto}}
          </v-list-item-title>

        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-container>
</template>

<script>
export default {
  components: {
    StatusChip : () => import('@/components/StatusChip.vue')
  },
  data() {
    return {
      favoritos: []
    }
  },
  methods: {
    async getFavoritos() {
      const { data } = await axios({
        method : 'GET',
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`
      })
      this.favoritos = data
    },
    async toggleFav(idEstudio, fav=false) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        data: {
          idEstudio
        },
      });
      this.$root.$emit('snack', `Estudio ${idEstudio} ${fav ? 'eliminado de' : 'añadido a'} favoritos` )
      this.$root.$emit('getFav')
    },
  },
  mounted() {
    this.getFavoritos()
  }
};
</script>

<style></style>
